<template>
  <div
    ref="chats"
    class="OperatorChats tw-pr-5"
    :class="[operatorChatsDirectionClass]"
    :style="{ [operatorChatsSide]: 0 }"
  >
    <template v-if="useAuthStore().user?.lang !== 'ua'">
      <OperatorChat
        v-if="opGroupChat"
        class="OperatorChat OpGroupChat tw-ml-1"
        title="Všichni"
        :chat="opGroupChat"
        :closeable="false"
      />

      <OperatorChat
        v-for="groupChat in groupChats"
        :key="`group-${groupChat.roomId}`"
        class="OperatorChat OpGroupChat tw-ml-1"
        :title="groupChat.title"
        :chat="$store.getters['chat/groupChat'](groupChat.roomId)"
        :closeable="false"
      />
    </template>

    <OperatorChat
      v-for="chat in openChats"
      :key="chat.receiver_id"
      class="OperatorChat tw-ml-1"
      :chat="chat"
    />
    <template v-if="boxedChats.length > 0">
      <el-popover
        placement="top-end"
        width="250"
        trigger="click"
      >
        <template #reference>
          <div

            class="AllChatsReference tw-p-3 tw-cursor-pointer tw-pointer-events-auto"
          >
            <icon-chat />
          </div>
        </template>
        <div class="BoxedChats tw-cursor-pointer tw-pointer-events-auto">
          <div
            v-for="boxedChat in boxedChats"
            :key="boxedChat.id"
            class="BoxedChat tw-flex tw-items-center tw-justify-between"
          >
            <div
              class="tw-p-3 tw-pr-0 tw-w-full"
              @click="open(boxedChat)"
            >
              {{ chatname(boxedChat) }}
            </div>
            <div
              class="tw-cursor-pointer tw-p-3 tw-text-text-light tw-flex tw-items-center"
              @click="close(boxedChat)"
            >
              <icon-close />
            </div>
          </div>
        </div>
      </el-popover>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    chats() {
      return this.$store.state.chat.operatorChats;
    },
    boxedChats() {
      return _differenceBy(this.chats, this.openChats);
    },
    openChats() {
      const openChats = this.chats.slice(this.maxOpenChats * -1);
      return openChats.reverse();
    },
    maxOpenChats() {
      return this.$store.state.chat.maxOpenChats;
    },
    opGroupChat() {
      if (this.$route.hash === '#windowed') {
        return false;
      }

      const roomId = 1;
      return this.$store.getters['chat/groupChat'](roomId);
    },
    groupChats() {
      if (this.$route.hash === '#windowed') {
        return [];
      }

      const chats = [];

      if (useAuthStore().user?.is_admin || useAuthStore().user?.service_id === 2) {
        chats.push({
          roomId: 3,
          title: 'Přepisovatelé',
        });
      }

      if (useAuthStore().user?.is_admin || useAuthStore().user?.service_id === 1) {
        chats.push({
          roomId: 2,
          title: 'Tlumočníci',
        });
      }

      return chats;
    },
    isActiveRequestOpened() {
      const requests = useNuxtApp().$store.state.operator.requests.requests;
      const request = requests.find((item) => item.id == useRoute().params.id) || {};

      return request?.state_id === 2;
    },
    operatorChatsSide() {
      return useVideoStore().isActive && this.isActiveRequestOpened ? 'left' : 'right';
    },
    operatorChatsDirectionClass() {
      return useVideoStore().isActive && this.isActiveRequestOpened ? 'tw-flex-row' : 'tw-flex-row-reverse';
    },
  },
  watch: {
    openChats() {
      window.localStorage.setItem('open-chats', JSON.stringify(this.openChats));
    },
    boxedChats() {
      window.localStorage.setItem('boxed-chats', JSON.stringify(this.boxedChats));
    },
  },
  mounted() {
    this.$nextTick(() => {
      const openChats = JSON.parse(window.localStorage.getItem('open-chats')) || [];
      const boxedChats = JSON.parse(window.localStorage.getItem('boxed-chats')) || [];

      const allChats = [];
      allChats.push(...boxedChats, ...openChats);
      this.$store.commit('chat/CHATS_SET', {
        receiver_id: -1, // to get correct chat info in store
        chats: allChats,
      });
    });
  },
  methods: {
    chatname(chat) {
      if (!chat.receiver) { return `Chat #${chat.receiver_id}` }

      return !this.isOrganization
        ? `${chat.receiver.first_name} ${chat.receiver.last_name}`
        : `${chat.receiver.name}`;
    },
    open(chat) {
      this.$store.commit('chat/CHAT_REPUSH', {
        receiver_id: chat.receiver_id,
      });
    },
    close(chat) {
      this.$store.dispatch('chat/closeChat', {
        receiver_id: chat.receiver_id,
      });
    },
  },
};
</script>

<style scoped>
.OperatorChats {
  min-height: 56px;
  z-index: 1234;

  display: flex;
  align-items: flex-end;
  pointer-events: none;

  .OperatorChat {
    pointer-events: auto;
  }

  .AllChatsReference {
    background-color: white;
    box-shadow: 0 25px 35px rgb(0 0 0 / 0.15);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}
</style>

<style>
.el-popover {
  padding: 0 !important;

  .BoxedChats {
    .BoxedChat {
      &:hover {
        background-color: theme('colors.light');
      }
    }
  }
}
</style>
