import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIconAccountArrowLeftOutline, LazyIconAndroid, LazyIconArrowFillDown, LazyIconArrowLeft, LazyIconArrowRight, LazyIconArrowsExpand, LazyIconArrowsShrink, LazyIconAttachment, LazyIconBadConnection, LazyIconBiggerText, LazyIconBurger, LazyIconButtonCursor, LazyIconCalendarMdi, LazyIconCalendarMuted, LazyIconCalendarXl, LazyIconCalendar, LazyIconCamera, LazyIconCaretDownRounded, LazyIconCaretUpRounded, LazyIconChat, LazyIconCheckCircle, LazyIconCheckDouble, LazyIconCheck, LazyIconCirclesLoading, LazyIconCircles, LazyIconClockMuted, LazyIconClock, LazyIconClone, LazyIconClose, LazyIconCoffeeXl, LazyIconCoffee, LazyIconContrast, LazyIconDisallow, LazyIconDotsHorizontal, LazyIconDotsVertical, LazyIconEar, LazyIconEdit, LazyIconElLoading, LazyIconElWarningFilled, LazyIconEmergency, LazyIconEnter, LazyIconFeedbackFaintly, LazyIconFeedbackGreat, LazyIconFeedbackOk, LazyIconFlag, LazyIconFullscreen, LazyIconGenderFemale, LazyIconGenderMale, LazyIconGroupXl, LazyIconGroup, LazyIconHospitalXl, LazyIconHospital, LazyIconImport, LazyIconInterpretingXl, LazyIconInterpreting, LazyIconIos, LazyIconIsTyping, LazyIconKeypad, LazyIconLeave, LazyIconLeft, LazyIconList, LazyIconLoading, LazyIconLogout, LazyIconMail, LazyIconMicMuted, LazyIconMic, LazyIconNotes, LazyIconOrganization, LazyIconPaperplane, LazyIconPause, LazyIconPhoneCancel, LazyIconPhone, LazyIconPlay, LazyIconPlus, LazyIconQr, LazyIconReload, LazyIconRepeat, LazyIconRight, LazyIconSearch, LazyIconSos, LazyIconSpeakerMuted, LazyIconSpeaker, LazyIconStar, LazyIconTranscriptXl, LazyIconTranscript, LazyIconTransfer, LazyIconTrashXl, LazyIconTrash, LazyIconUserXl, LazyIconUser, LazyIconWarningTriangle, LazyIconWarningXl, LazyIconWarning, LazyIconWeb } from '#components'
const lazyGlobalComponents = [
  ["IconAccountArrowLeftOutline", LazyIconAccountArrowLeftOutline],
["IconAndroid", LazyIconAndroid],
["IconArrowFillDown", LazyIconArrowFillDown],
["IconArrowLeft", LazyIconArrowLeft],
["IconArrowRight", LazyIconArrowRight],
["IconArrowsExpand", LazyIconArrowsExpand],
["IconArrowsShrink", LazyIconArrowsShrink],
["IconAttachment", LazyIconAttachment],
["IconBadConnection", LazyIconBadConnection],
["IconBiggerText", LazyIconBiggerText],
["IconBurger", LazyIconBurger],
["IconButtonCursor", LazyIconButtonCursor],
["IconCalendarMdi", LazyIconCalendarMdi],
["IconCalendarMuted", LazyIconCalendarMuted],
["IconCalendarXl", LazyIconCalendarXl],
["IconCalendar", LazyIconCalendar],
["IconCamera", LazyIconCamera],
["IconCaretDownRounded", LazyIconCaretDownRounded],
["IconCaretUpRounded", LazyIconCaretUpRounded],
["IconChat", LazyIconChat],
["IconCheckCircle", LazyIconCheckCircle],
["IconCheckDouble", LazyIconCheckDouble],
["IconCheck", LazyIconCheck],
["IconCirclesLoading", LazyIconCirclesLoading],
["IconCircles", LazyIconCircles],
["IconClockMuted", LazyIconClockMuted],
["IconClock", LazyIconClock],
["IconClone", LazyIconClone],
["IconClose", LazyIconClose],
["IconCoffeeXl", LazyIconCoffeeXl],
["IconCoffee", LazyIconCoffee],
["IconContrast", LazyIconContrast],
["IconDisallow", LazyIconDisallow],
["IconDotsHorizontal", LazyIconDotsHorizontal],
["IconDotsVertical", LazyIconDotsVertical],
["IconEar", LazyIconEar],
["IconEdit", LazyIconEdit],
["IconElLoading", LazyIconElLoading],
["IconElWarningFilled", LazyIconElWarningFilled],
["IconEmergency", LazyIconEmergency],
["IconEnter", LazyIconEnter],
["IconFeedbackFaintly", LazyIconFeedbackFaintly],
["IconFeedbackGreat", LazyIconFeedbackGreat],
["IconFeedbackOk", LazyIconFeedbackOk],
["IconFlag", LazyIconFlag],
["IconFullscreen", LazyIconFullscreen],
["IconGenderFemale", LazyIconGenderFemale],
["IconGenderMale", LazyIconGenderMale],
["IconGroupXl", LazyIconGroupXl],
["IconGroup", LazyIconGroup],
["IconHospitalXl", LazyIconHospitalXl],
["IconHospital", LazyIconHospital],
["IconImport", LazyIconImport],
["IconInterpretingXl", LazyIconInterpretingXl],
["IconInterpreting", LazyIconInterpreting],
["IconIos", LazyIconIos],
["IconIsTyping", LazyIconIsTyping],
["IconKeypad", LazyIconKeypad],
["IconLeave", LazyIconLeave],
["IconLeft", LazyIconLeft],
["IconList", LazyIconList],
["IconLoading", LazyIconLoading],
["IconLogout", LazyIconLogout],
["IconMail", LazyIconMail],
["IconMicMuted", LazyIconMicMuted],
["IconMic", LazyIconMic],
["IconNotes", LazyIconNotes],
["IconOrganization", LazyIconOrganization],
["IconPaperplane", LazyIconPaperplane],
["IconPause", LazyIconPause],
["IconPhoneCancel", LazyIconPhoneCancel],
["IconPhone", LazyIconPhone],
["IconPlay", LazyIconPlay],
["IconPlus", LazyIconPlus],
["IconQr", LazyIconQr],
["IconReload", LazyIconReload],
["IconRepeat", LazyIconRepeat],
["IconRight", LazyIconRight],
["IconSearch", LazyIconSearch],
["IconSos", LazyIconSos],
["IconSpeakerMuted", LazyIconSpeakerMuted],
["IconSpeaker", LazyIconSpeaker],
["IconStar", LazyIconStar],
["IconTranscriptXl", LazyIconTranscriptXl],
["IconTranscript", LazyIconTranscript],
["IconTransfer", LazyIconTransfer],
["IconTrashXl", LazyIconTrashXl],
["IconTrash", LazyIconTrash],
["IconUserXl", LazyIconUserXl],
["IconUser", LazyIconUser],
["IconWarningTriangle", LazyIconWarningTriangle],
["IconWarningXl", LazyIconWarningXl],
["IconWarning", LazyIconWarning],
["IconWeb", LazyIconWeb],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
