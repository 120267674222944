<template>
  <div>
    <el-dialog
      :model-value="useDialogStore().anonymousRequest.visible"
      width="580px"

      :close-on-mask-click="false"
      :close-on-press-escape="false"
      :show-close="false"
      modal-class="tw-bg-light"

      @accept="handleAccept"
      @dismiss="handleDismiss"
    >
      <template #header>
        <div class="DialogTitle">
          <h4 class="tw-text-primary-500">
            <icon-emergency />
            <span>Tato služba je určena lidem se sluchovým postižením.</span>
          </h4>
        </div>
      </template>

      <div class="tw-text-base tw-px-6">
        <p class="tw-mb-6">
          Přejete si volat prostřednictvím tlumočníka českého znakového jazyka nebo přepisovatele českého jazyka?
        </p>

        <iframe
          width="560"
          height="315"
          class="tw-max-w-full"
          src="https://www.youtube-nocookie.com/embed/R7ipTuf1wd8?autoplay=1&loop=1&mute=1&list=PLjVeCzzEMsh3YP6sM0j_qi0wTdf66iW0h"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
        <a href="/">
          <logo-svg
            class="tw-text-primary-500 tw-fixed tw-top-6 tw-left-7 tw-z-50"
          />
        </a>
      </div>

      <slot name="footer">
        <span
          class="tw-flex tw-items-center tw-justify-between tw-px-6 tw-mt-8 tw-flex-col-reverse sm:tw-flex-row tw-gap-y-5"
        >
          <el-button
            size="large"
            class="el-button--no-shadow"
            @click="handleDismiss"
          >
            Ne, zpět
          </el-button>
          <div
            v-loading="isLoading"
            class="tw-flex tw-flex-col sm:tw-flex-row tw-gap-3 tw-w-full sm:tw-w-auto"
          >
            <el-button
              size="large"
              class="tw-px-6 el-button--tertiary tw-w-full"
              @click="() => handleAccept(2)"
            >
              Zahájit přepis
            </el-button>
            <el-button
              size="large"
              class="tw-px-6 el-button--secondary tw-w-full tw-m-0"
              @click="() => handleAccept(1)"
            >
              Zahájit tlumočení
            </el-button>
          </div>
        </span>
      </slot>
    </el-dialog>
  </div>
</template>

<script setup>
import LogoSvg from '~/assets/img/logo.svg';

const emit = defineEmits(['accepted']);

// Refs
const isLoading = ref(false);

// Methods
const handleAccept = async(service_id) => {
  isLoading.value = true;

  const hasAccessToEmergency = await useIpLocation().hasAccessToEmergency();
  if (!hasAccessToEmergency) {
    await sleep(2000);
    useIpLocation().accessToActionDeniedMessage();
    isLoading.value = false;

    return;
  }

  emit('accepted');

  try {
    const data = {
      service_id,
      type_id: service_id === 1 ? 1 : undefined,
      book_time: null,
      organization_uuid: useDialogStore().anonymousRequest.organizationUUID,
      is_from_qr: useDialogStore().anonymousRequest.isFromQR,
    };

    if (!data.organization_uuid) {
      ElMessage.error({
        message: 'Omlouváme se, ale nepodařilo se najít organizaci.',
        duration: 6000,
        showClose: true,
      });
      isLoading.value = false;
      return;
    }

    const response = await useApi.post(`/client/anon-request/`, { body: data });

    useAuthStore().setAuth({
      access: response.access,
      refresh: response.refresh,
    });
    await sleep(200);
    await useAuthStore().fetchUser();
    await sleep(800);

    navigateTo(`/requests/${response.id}/waiting`);

    closeDialog();
  } catch (error) {
    console.error(error);
    let message = 'Omlouváme se, ale došlo k chybě při vytváření požadavku.';

    if (error.response?.status === 400) {
      if (service_id === 1) {
        if (useDialogStore().anonymousRequest.isFromQR) {
          message = 'Běžné tlumočení je k dispozici od 7:00 do 22:00. Od 22:00 do 7:00 je otevřena pouze SOS linka.';
          useDialogStore().open('confirmEmergency');
          closeDialog();
        } else {
          message = `Vytvořit požadavek do fronty nyní nelze, protože na toto téma tlumočník nyní nemá službu.<br>
          Pokud chcete vědět, kdy má tlumočník službu, klikněte <a href="/kalendar/${service_id === 1 ? 'tlumoceni' : 'prepis'}" target="_blank">zde</a>`;
        }
      } else if (service_id === 2) {
        message = 'Přepis je k dispozici ve všední dny 7:00-19:00. Pro svůj požadavek nyní využijte službu tlumočení, kde je přepis také možný.';
      }
    }

    ElMessage.error({
      message,
      duration: 6000,
      dangerouslyUseHTMLString: true,
      showClose: true,
    });
  }

  isLoading.value = false;
};

const handleDismiss = () => {
  window.history.back();

  setTimeout(() => {
    window.location.href = document.referrer || 'https://tichalinka.cz';
  }, 500);
};

const closeDialog = () => {
  useDialogStore().close('anonymousRequest');
  useDialogStore().anonymousRequest.organizationUUID = null;
  useDialogStore().anonymousRequest.isFromQR = false;
};
</script>
