const getMessageProps = (message) => {
  let commands;
  let stateArray;
  let inboxIdField;
  let prefix = '';

  if (message.request_id) {
    // is request message
    commands = {
      fetch: 'fetch_request_messages',
      create: 'new_request_message',
      edit: 'edit_request_message',
    };
    stateArray = 'requestChats';
    inboxIdField = 'request_id';
    prefix = 'REQUEST_';
  } else if (message.receiver_id) {
    // is operator2operator message
    commands = {
      fetch: 'fetch_messages',
      create: 'new_message',
      edit: 'edit_message',
    };
    stateArray = 'operatorChats';
    inboxIdField = 'receiver_id';
    prefix = 'OPERATOR_';
  } else {
    // group chat
    commands = {
      fetch: 'fetch_room_messages',
      create: 'new_room_message',
      edit: 'edit_room_message',
    };
    stateArray = 'groupChats';
    inboxIdField = 'room_id';
    prefix = 'GROUP_';
  }
  return { commands, stateArray, inboxIdField, prefix };
};

export const state = () => ({
  requestChats: [],
  operatorChats: [],
  groupChats: [
    {
      room_id: 1, // all operators chat
      messages: [],
    },
    {
      room_id: 2, // interpreters chat
      messages: [],
    },
    {
      room_id: 3, // transcribers chat
      messages: [],
    },
  ],
  maxOpenChats: 2,
});

export const mutations = {
  MESSAGE_UPDATE(state, { msg, stateArray, chatIndex, msgIndex }) {
    if (chatIndex >= 0 && msgIndex !== undefined && msgIndex >= 0) {
      const currentMessage = state[stateArray][chatIndex].messages[msgIndex];
      state[stateArray][chatIndex].messages.splice(msgIndex, 1, { ...currentMessage, ...msg });
    } else {
      console.error(`MESSAGE_UPDATE FAIL: invalid chatIndex (${chatIndex}) or messageIndex (${msgIndex})`);
    }
  },

  MESSAGES_UPDATE_READ_BY(state, payload) {
    const { stateArray, inboxIdField } = getMessageProps(payload);

    const chatIndex = state[stateArray].findIndex((e) => e[inboxIdField] == payload[inboxIdField] || e[inboxIdField] == payload.sender?.id);
    if (chatIndex !== -1) {
      const messageIndex = state[stateArray][chatIndex].messages.findIndex((e) => e.id == payload.id);
      if (messageIndex !== -1) {
        // update all messages in same stateArray and chatIndex with same or lower id
        state[stateArray][chatIndex].messages.forEach((message) => {
          if (message.id <= payload.id && message.id) {
            message.read_by = payload.read_by;
          }
        });
      }
    }
  },

  MESSAGE_ADD(state, { msg, me, stateArray, inboxIdField, is_typing, third_party, isFromWebSocket = false }) {
    // if I am the recipient of the message, use sender.id as the recipient for my messages
    // store it that way
    let id = msg[inboxIdField];
    if (msg.receiver_id == me.id) {
      id = msg.sender.id;
    }

    const chatIndex = state[stateArray].findIndex((e) => e[inboxIdField] == id);

    msg.is_typing = is_typing;
    msg.third_party = third_party;
    msg.isFromWebSocket = isFromWebSocket;

    if (is_typing) {
      const isTypingMessageIndex = state[stateArray][chatIndex] ? state[stateArray][chatIndex].messages.findIndex((message) => message.is_typing && message.sender.id == msg.sender.id) : -1;

      if (isTypingMessageIndex != -1) {
        state[stateArray][chatIndex].messages.splice(isTypingMessageIndex, 1, msg);
      } else if (state[stateArray][chatIndex]) {
        state[stateArray][chatIndex].messages.push(msg);
      } else {
        console.warn('Trying receive typing message to undefined chat.');
      }
    } else if (msg.content && msg.content.length) {
      if (chatIndex != -1) {
        state[stateArray][chatIndex].messages.push(msg);
      } else {
        state[stateArray].push({
          [inboxIdField]: id,
          receiver: msg.sender,
          messages: [msg],
          open: false,
        });
      }

      // Clear is_typing messages
      if (state[stateArray][chatIndex]) {
        state[stateArray][chatIndex].messages = state[stateArray][chatIndex].messages.filter((message) => (!message.is_typing || message.sender.id != me.id));
      }
    }
  },

  MESSAGE_DELETE(state, message) {
    const msg = message;

    const { stateArray, inboxIdField } = getMessageProps(msg);
    let msgIndex = -1;
    const chatIndex = state[stateArray].findIndex((e) => e[inboxIdField] == msg[inboxIdField]);
    if (chatIndex != -1) {
      msgIndex = state[stateArray][chatIndex].messages.findIndex((e) => e.id == msg.id);
    }
    if (chatIndex != -1 && msgIndex != -1) {
      state[stateArray][chatIndex].messages.splice(msgIndex, 1);
    } else {
      console.error(`MESSAGE_UPDATE FAIL: invalid chatIndex (${chatIndex}) or messageIndex (${msgIndex})`);
    }
  },

  CHAT_POPULATE(state, payload) {
    const { messages, request_id, receiver_id } = payload;
    const msgs = messages.map((e) => ({ ...e }));
    msgs.sort((a, b) => dayjs(a.created_at).diff(dayjs(b.created_at)));

    const { stateArray, inboxIdField } = getMessageProps(payload);

    const chatIndex = state[stateArray].findIndex((e) => e[inboxIdField] == payload[inboxIdField]);
    if (chatIndex != -1) {
      const previousErrorMessages = state[stateArray][chatIndex].messages.filter(({ content, status }) => content && (status === 'error' || status === 'pending'));
      state[stateArray][chatIndex].messages = [
        ...msgs,
        ...previousErrorMessages,
      ];
    } else {
      state[stateArray].push({
        request_id,
        receiver_id,
        messages: msgs,
        open: false,
      });
    }
  },

  CHAT_UPDATE(state, payload) {
    const { stateArray, inboxIdField } = getMessageProps(payload);

    const chatIndex = state[stateArray].findIndex((e) => e[inboxIdField] == payload[inboxIdField]);
    if (chatIndex != -1) {
      state[stateArray].splice(chatIndex, 1, {
        ...state[stateArray][chatIndex],
        ...payload,
      });
    } else {
      state[stateArray].push({
        messages: [],
        ...payload,
      });
    }
  },

  CHAT_REMOVE(state, payload) {
    const { stateArray, inboxIdField } = getMessageProps(payload);

    const chatIndex = state[stateArray].findIndex((e) => e[inboxIdField] == payload[inboxIdField]);
    if (chatIndex !== -1) {
      state[stateArray].splice(chatIndex, 1);
    };
  },

  CHAT_REPUSH(state, payload) {
    const { stateArray, inboxIdField } = getMessageProps(payload);
    const chatIndex = state[stateArray].findIndex((e) => e[inboxIdField] == payload[inboxIdField]);
    if (chatIndex != -1) {
      const shifted = state[stateArray].shift();
      shifted.open = true;
      state[stateArray].push(shifted);
    };
  },

  CHATS_SET(state, payload) {
    const { stateArray } = getMessageProps(payload);
    state[stateArray] = payload.chats;
  },

};

export const actions = {
  // INCOMING FROM WEBSOCKET
  onNewMessage({ commit, state }, { temp_id, message, is_typing, third_party }) {
    const msg = message;
    const me = useAuthStore().user;
    msg.status = 'delivered'; // message received from websocket
    const { stateArray, inboxIdField } = getMessageProps(msg);

    const chatIndex = state[stateArray].findIndex((e) => e[inboxIdField] == msg[inboxIdField]);
    let msgIndex = -1;
    if (chatIndex != -1) {
      msgIndex = state[stateArray][chatIndex].messages.findIndex((e) => e.temp_id == temp_id);
    }

    if (chatIndex !== -1 && msgIndex !== -1) {
      commit('MESSAGE_UPDATE', { msg, stateArray, inboxIdField, chatIndex, msgIndex });
    } else {
      // should be inbound messages
      commit('MESSAGE_ADD', { msg, me, stateArray, inboxIdField, is_typing, third_party, isFromWebSocket: true });
    }
  },

  onFetchMessages({ state, commit }, { messages, request_id, receiver_id, room_id }) {
    commit('CHAT_POPULATE', { messages, request_id, receiver_id, room_id });
  },

  onEditMessage({ commit, state }, { message }) {
    const msg = message;

    const { stateArray, inboxIdField } = getMessageProps(msg);
    let msgIndex = -1;
    const chatIndex = state[stateArray].findIndex((e) => e[inboxIdField] == msg[inboxIdField]);
    if (chatIndex != -1) {
      msgIndex = state[stateArray][chatIndex].messages.findIndex((e) => e.id == msg.id);
    }
    if (chatIndex != -1 && msgIndex != -1) {
      msg.edited = true;
      commit('MESSAGE_UPDATE', { msg, stateArray, inboxIdField, chatIndex, msgIndex });
    }
  },

  // TO WEBSOCKET
  sendMessage({ commit, state }, message) {
    const msg = message;
    const me = useAuthStore().user;
    const is_typing = message.is_typing;
    const third_party = message.third_party || false;
    const { commands, stateArray, inboxIdField } = getMessageProps(msg);

    useNuxtApp().$socket.sendObj({
      command: commands.create,
      inbox_name: message[inboxIdField],
      temp_id: message.temp_id,
      is_typing,
      third_party,
      message: message.content,
    });

    if (!is_typing) {
      commit('MESSAGE_ADD', { msg, me, stateArray, inboxIdField, is_typing, third_party });

      setTimeout(() => {
        const chatIndex = state[stateArray].findIndex((e) => e[inboxIdField] == message[inboxIdField]);
        const chat = state[stateArray][chatIndex];

        const msg = chat ? { ...chat.messages.find((e) => e.temp_id == message.temp_id) } : false;

        if (msg && msg.status === 'pending') {
          const msgIndex = state[stateArray][chatIndex].messages.findIndex((e) => e.id == message.id);
          msg.status = 'error';
          commit('MESSAGE_UPDATE', {
            msg,
            stateArray,
            inboxIdField,
            chatIndex,
            msgIndex,
          });
        }
      }, 10000);
    }
  },

  fetchMessages({ commit, state }, receiverObject) {
    const { commands, inboxIdField } = getMessageProps(receiverObject);

    const inbox_name = receiverObject[inboxIdField];

    useNuxtApp().$socket.sendObj({
      command: commands.fetch,
      inbox_name,
    });
  },
  editMessage(_, message) {
    const { commands, inboxIdField } = getMessageProps(message);

    useNuxtApp().$socket.sendObj({
      command: commands.edit,
      inbox_name: message[inboxIdField],
      message_id: message.id,
      message: message.content,
    });
  },
  markMessageReadBy(_, { command, requestId, roomId, receiverId, messageId }) {
    useNuxtApp().$socket.sendObj({
      command,
      request_id: requestId,
      room_id: roomId,
      receiver_id: receiverId,
      message_id: messageId,
    });
  },
  updateMessageReadBy({ commit }, { message }) {
    commit('MESSAGES_UPDATE_READ_BY', message);
  },

  // OTHER
  openChat({ commit, getters }, { receiver, receiver_id, room_id }) {
    commit('CHAT_UPDATE', {
      receiver,
      receiver_id,
      room_id,
      open: true,
    });

    if (receiver_id) {
      const openChats = getters.openOperatorChats.map((e) => e.receiver_id);
      if (!openChats.includes(receiver_id)) {
        commit('CHAT_REPUSH', {
          receiver_id,
        });
      }
    }
  },
  hideChat({ commit, getters }, { receiver_id, room_id }) {
    const chat = getters.operatorChat(receiver_id) || getters.groupChat(room_id);

    if (chat) {
      commit('CHAT_UPDATE', {
        receiver_id,
        room_id,
        open: false,
      });
    }
  },
  closeChat({ commit }, { receiver_id }) {
    commit('CHAT_REMOVE', { receiver_id });
  },
  toggleEditMessage({ commit, state, dispatch }, message) {
    const msg = message;
    const { stateArray, inboxIdField } = getMessageProps(msg);
    const chatIndex = state[stateArray].findIndex((e) => e[inboxIdField] == message[inboxIdField]);
    let msgIndex = -1;
    if (chatIndex != -1) {
      msgIndex = state[stateArray][chatIndex].messages.findIndex((e) => e.id == message.id);
    }

    if (chatIndex != -1 && msgIndex != -1) {
      if (!msg.edit) { dispatch('clearEditMessage', msg) } // clear all edit message things
      msg.edit = !msg.edit;
      commit('MESSAGE_UPDATE', { msg, stateArray, inboxIdField, chatIndex, msgIndex });
    }
  },
  clearEditMessage({ commit, state }, message) {
    const msg = message;
    const { stateArray, inboxIdField } = getMessageProps(msg);
    const chatIndex = state[stateArray].findIndex((e) => e[inboxIdField] == message[inboxIdField]);
    if (chatIndex != -1) {
      const messages = state[stateArray][chatIndex].messages.map((mes) => ({ ...mes, edit: false }));
      commit('CHAT_UPDATE', {
        [inboxIdField]: message[inboxIdField],
        messages,
      });
    }
  },

};

export const getters = {
  requestMessages: (state) => (request_id) => {
    const found = state.requestChats.find((chat) => chat.request_id == request_id);
    return found ? found.messages : [];
  },
  operatorChat: (state) => (receiver_id) => {
    const found = state.operatorChats.find((chat) => chat.receiver_id == receiver_id);
    return found;
  },
  groupChat: (state) => (room_id) => {
    const found = state.groupChats.find((chat) => chat.room_id == room_id);
    return found;
  },
  openOperatorChats: (state) => {
    const { stateArray } = getMessageProps({ receiver_id: -1 });
    const openChats = state[stateArray].slice(state.maxOpenChats * -1);
    return openChats;
  },
};
