import { ElMessage } from 'element-plus';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:chunkError', ({ error }) => {
    console.warn('[chunk-error.js] chunk load error', error);

    ElMessage({
      type: 'warning',
      showClose: true,
      dangerouslyUseHTMLString: true,
      grouping: true,
      customClass: '[&_.el-badge]:tw-hidden',
      message: `Nová verze aplikace je nyní k dispozici. <a href='#' class='underline' onclick='javascript:window.location.reload()'>Načíst znovu</a>`,
    });
  });
});
