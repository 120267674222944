<template>
  <section
    class="public-page"
    :class="useRoute()?.name?.includes('kalendar-type') ? 'public-page--calendar' : ''"
  >
    <div class="content">
      <PublicHeader />
      <div class="content__inner">
        <slot />
      </div>
      <PublicCta
        v-if="useBreakpoint.smaller('lg').value && !useRoute()?.name?.includes('kalendar-type')"
        class="tw-text-center tw-mb-6"
      />
      <PublicFooter />
    </div>

    <PublicEmergency v-if="$i18n.locale !== 'ua'" />

    <ConfirmEmergencyDialog />
    <ConfirmAnonymousRequestDialog />
    <CapacitorCodePush
      v-if="useRuntimeConfig().public.isIOSApp"
    />
  </section>
</template>

<script setup>
// Refs
const route = useRoute();

// Watchers
watch(() => useAuthStore().isLoggedIn, () => {
  if (useAuthStore().isLoggedIn && !route.name?.includes('kalendar-type')) {
    return navigateTo('/');
  }
}, { immediate: true });

// Lifecycle
onMounted(() => {
  if (useRoute().query.organization) {
    const organizationUUID = useRoute().query.organization;

    if (useAuthStore().isLoggedIn) {
      useAuthStore().logout();
    }

    if (useRoute().query.qr) {
      useDialogStore().anonymousRequest.isFromQR = true;
    }

    useDialogStore().open('anonymousRequest');
    useDialogStore().anonymousRequest.organizationUUID = organizationUUID;
    useDialogStore().anonymousRequest.isFromQR = Boolean(useRoute().query.qr);
  }
});
</script>
