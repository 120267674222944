import { useWebSocket } from '@vueuse/core';

export default defineNuxtPlugin((nuxtApp) => {
  const websocketClosedNotify = ref(null);

  const commandsDispatchMap = {
    messages: 'chat/onFetchMessages',
    new_message: 'chat/onNewMessage',
    edit_message: 'chat/onEditMessage',
    update_user: 'websocket/updateUser',
    update_operator: 'websocket/updateOperator',
    new_request: 'websocket/addRequest',
    update_request: 'websocket/updateRequest',
    delete_request: 'websocket/removeRequest',
    'forward-request': 'websocket/forwardRequest',
    'forward-request-declined': 'websocket/forwardedRequest',
    invite_assist_request: 'websocket/inviteAssistRequest',
    decline_assist_request: 'websocket/declineAssistRequest',
    request_queue: 'websocket/requestQueue',
    read_private_message: 'chat/updateMessageReadBy',
    read_room_message: 'chat/updateMessageReadBy',
    read_request_message: 'chat/updateMessageReadBy',
    refresh_browser: null,
    logout_everywhere: null,
  };

  // Computed
  const websocketUrl = computed(() => {
    let token = window.localStorage.getItem('token');

    if (useAuthStore().isLoggedIn && token?.length) {
      const accessToken = token.split(' ')[1]; // Return accessToken without Bearer prefix
      return `${useApi.getBaseURL().replace('http', 'ws')}/socket?token=${accessToken}`;
    } else {
      return;
    }
  });

  // Methods
  const showErrorNotify = () => {
    if (!websocketClosedNotify.value && useAuthStore().isLoggedIn) {
      if (!document.title.includes('🔴')) {
        document.title = `🔴 ${document.title}`;
      }

      websocketClosedNotify.value = ElNotification.error({
        title: 'Došlo k chybě při spojení',
        message: '<a href="#" onclick="window.location.reload()">Zkusit znovu</a>',
        dangerouslyUseHTMLString: true,
        duration: 0,
        offset: 80,
        position: 'top-right',
        showClose: false,
        customClass: 'el-notification--danger',
        onClose: () => {
          if (document.title.includes('🔴')) {
            document.title = document.title.replace('🔴 ', '');
          }
        },
      });
    }
  };

  const onConnected = () => {
    console.log('[ws] connected');
    nuxtApp.$store.commit('websocket/SOCKET_ONOPEN');

    if (websocketClosedNotify.value) {
      websocketClosedNotify.value.close();
      websocketClosedNotify.value = null;
    }
  };

  const onDisconnected = (ws, event) => {
    console.log('[ws] disconnected', event?.code, event);
    nuxtApp.$store.commit('websocket/SOCKET_ONCLOSE');

    if (event.code === 1000 || event.code === 1001 || event.code === 1005) {
      console.log('[ws] disconnected silently');
    } else {
      showErrorNotify();
    }
  };

  const onMessage = (ws, event) => {
    let payload = false;
    try {
      payload = JSON.parse(event.data);
    } catch (error) {
      console.error('[ws] UnexpectedJSONParseError: ', error.message, event.data);
    }

    if (payload) {
      console.log('[ws] message ', JSON.parse(JSON.stringify(payload)));
      nuxtApp.$store.commit('websocket/SOCKET_ONMESSAGE', _cloneDeep(payload));

      if (payload.command && commandsDispatchMap[payload.command]) {
        nuxtApp.$store.dispatch(commandsDispatchMap[payload.command], payload);
      } else if (payload.command && payload.command === 'logout_everywhere') {
        if (useAuthStore().isLoggedIn) {
          useLogout();
        }
      } else if (payload.command && payload.command === 'refresh_browser') {
        window.location.reload();
      } else if (payload.command && payload.command === 'read_messages') {
        // Read messages
      } else if (payload.command && payload.command === 'request_equip_update') {
        // Request equip update - handled in component
      } else {
        console.warn('[ws] Unknown command: ', payload);
      }
    }
  };

  const onError = (ws, event) => {
    console.log('[ws] error', event);
    nuxtApp.$store.commit('websocket/SOCKET_ONCLOSE');
    showErrorNotify();
  };


  const socket = useWebSocket(() => websocketUrl.value || undefined, {
    immediate: false,
    autoReconnect: {
      retries: 10,
      delay: ms('10s'),
      onFailed: () => {
        console.log('[ws] autoReconnect retries failed');
      },
    },
    onConnected,
    onDisconnected,
    onError,
    onMessage,
  });

  socket.sendObj = (payload) => {
    if (socket?.status?.value === 'OPEN') {
      console.log('[ws] send', payload);
      socket.send(JSON.stringify(payload));
    } else {
      console.warn('[ws] socket is not open to sendObj', socket?.status?.value);
    }
  };

  // Helpers for debugging
  window.websocketClose = () => socket.close();
  window.websocketOpen = () => socket.open();
  window.websocketSendObj = (payload) => socket.sendObj(payload);

  return {
    provide: {
      socket: socket,
    },
  };
});
