<template>
  <div
    v-if="color"
    class="ServiceIcon"
    :class="[
      color,
      `ServiceIcon--${size}`,
    ]"
  >
    <component
      :is="icon"
      class="tw-m-1"
    />
  </div>
</template>


<script setup>
const props = defineProps({
  serviceId: {
    type: Number,
    required: true,
    default: 1,
  },
  size: {
    type: String,
    default: '',
  },
});

// Computed
const icon = computed(() => {
  if (Number(props.serviceId) === 1) {
    return 'icon-interpreting-xl';
  }

  if (Number(props.serviceId) === 2) {
    return 'icon-transcript-xl';
  }

  if (Number(props.serviceId) === 3) {
    return 'icon-group-xl';
  }

  return undefined;
});

const color = computed(() => {
  if (Number(props.serviceId) === 1) {
    return 'color-secondary';
  }

  if (Number(props.serviceId) === 2) {
    return 'color-tertiary';
  }

  if (Number(props.serviceId) === 3) {
    return 'color-primary';
  }

  return undefined;
});
</script>

<style scoped>
.ServiceIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid;

  width: 48px;
  height: 48px;

  @apply tw-shrink-0;

  svg {
    width: 30px;
    height: 30px;
  }

  &--medium {
    width: 40px;
    height: 40px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &--small {
    width: 32px;
    height: 32px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
